.s-card-main {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    align-content: stretch;
    margin: 10px 0px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    overflow: hidden;
}

.s-profile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px;
    gap: 2%;
}

.view-profile-card-btn-mobile {
    padding: 2px 6px !important;
}

.s-profile-p-p {
    margin-left: 0%;
}

.s-profile-n-d-c {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: flex-start!important;
}

.s-duration-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 10px;
}

.s-profile-profiles {
    margin: 10px 10px;
}

.s-mobile-overflow-h {
    overflow: hidden;
}

/* .table-admin:nth-child(2) > .table-admin:nth-child(2) {
    background-color: #f5f5f5;
} */

.s-mobile-card,
.s-mobile-profile-card {
    display: none!important;
}


.s-desktop-card {
    max-width: 900px !important;
    margin: 15px auto !important;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px!important; */
    border-radius: 10px;
}

.s-desktop-card-remove-margin-desktop{
    margin: 0px !important;
}


.s-desktop-card-mx{
    max-width: 100% !important;
    gap: 1rem;
    background-color: rgb(255, 222, 222);
    border: 1px solid black;
}
.s-mobile-card-mx {
    background-color: rgb(255, 222, 222);
    border: 1px solid black;
}

.s-desktop-card-mx>div {
    flex: 1;
}

.s-desktop-card-mx>.rounded-sm,
.s-mobile-card-mx>.rounded-sm{
    background-color: white;
}

.s-desktop-card-mx>div>div>.MuiInputBase-colorPrimary,
.s-mobile-card-mx>div>div>.MuiInputBase-colorPrimary {
    background-color: white;
}

.s-desktop-card-mx>div>.MuiInputBase-colorPrimary,
.s-mobile-card-mx>div>.MuiInputBase-colorPrimary {
    background-color: white;
}

.s-desktop-card-mx>div>div>.MuiChip-colorDefault,
.s-mobile-card-mx>div>div>.MuiChip-colorDefault {
    background-color: white;
    border: 1px solid #222222;
    padding: 0.3rem 0;
    align-items: start;
}

.s-desktop-card-mx>div>div>.MuiChip-colorDefault>svg,
.s-mobile-card-mx>div>div>.MuiChip-colorDefault>svg {
    color: #323232;
}

.s-mobile-btn {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset, grey 0px 0px 6px !important;
}

.s-mobile-dis-btn {
    border-width: 1px !important;
    border-style: solid !important;
    background-color: white !important;
}

.s-mobile-interviewslot-move-up {
    transform: translateY(-3px);
}

.s-mobile-resume-btn {
    /* position: absolute!important; */
    top: 0px;
    right: 0px;
    width: fit-content;
    border-radius: 0px 10px 0px 10px !important;
    margin-left: auto !important;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;

}

.profilechange {
    grid-template-columns: 70% 30%;
}

.change-main {
    flex-direction: row;
}

.change-profile-completion {}

.change-find-an {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
}

.s-mobile-profile-subHeading {}


/* slot choose */

.slot-headding {
    font-size: clamp(16px, 5vw, 30px) !important;
}

.slot-name {
    font-size: clamp(16px, 4vw, 20px) !important;
}

.s-mobile-calendar-button {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
}

.s-mobile-calendar-calendar {
    position: absolute;
    z-index: 1;
    top: 37px;
    right: 0px;
}


@media screen and (max-width: 900px) {
    .s-mobile-profile-card {
        display: flex;
        gap: 0.5rem;
        flex-direction: row-reverse;
    }

    .s-desktop-profile-card {
        display: none !important;
    }

    .profilechange {
        grid-template-columns: 100%;
    }
}

@media screen and (max-width: 700px) {
    .change-profile-completion {}

    .change-main {
        flex-direction: column-reverse;
    }

    .change-find-an {
        max-width: max-content;
        flex-direction: column;
        flex-direction: row;
        align-items: center;
    }
}

@media screen and (max-width: 500px) {
    .change-find-an {
        flex-direction: column;
    }

    .font-12-500 {
        margin-top: 5px !important;
        ;
        font-size: 12px !important;
    }

    .font-14-500 {
        font-size: 14px !important;
    }

    .font-16-500 {
        font-size: 16px !important;
    }

    .padding-5-500 {
        padding: 5px !important;
    }
}

.s-card-height-56 {
    height: 56px;
}

.s-mobile-changing-chip-property,
.s-mobile-changing-chip-property>span {
    height: auto !important;
    white-space: normal !important;
}

.s-mobile-admin-searchbar {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 2s ease-in-out;
    border: 1px solid;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
.MuiMenuItem-root {
    white-space: normal !important;
}

.mobile-core-chip{
    max-width: 220px;
}


@media screen and (max-width: 600px) {
    .s-desktop-card {
        display: none !important;
    }

    .s-mobile-admin-searchbar {
        padding: 0px 10px !important;
    }

    .s-mobile-admin-searchbar:focus-within {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transition: all 0.5s ease-in-out;
    }

    .s-mobile-card {
        display: flex!important;
    }

    .mobile-core-chip{
        max-width: 100%;
    }

    .mobile-core-chip>div{
        height: fit-content!important;
        padding-top: 2px!important;
        padding-bottom: 2px!important;
    }

    .mobile-core-chip>div>span{
        white-space: normal;
    }

    .s-mobile-tabs-list-center>div>.MuiTabs-flexContainer {
        justify-content: center !important;
    }

    .s-mobile-overflow-h {
        overflow: visible;
    }

    .hide-picker {
        display: none !important;
    }

    .s-mobile-search-3filter {
        flex-direction: row !important;
        justify-content: center !important;
    }

    .s-mobile-flex-dir-col {
        flex-direction: column;
    }

    .s-mobile-flex-dir-row {
        flex-direction: column;
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 5px !important;
        font-size: 14px !important;
        text-align: left !important;
    }

    .s-card-height-56 {
        height: 40px !important;
    }

    .element>input {
        padding: 5px !important;
        font-size: 0.9rem !important;
    }

    .PhoneInputInput {
        height: 40px !important;
        font-size: 14px !important;
    }

    .s-mobile-profile-subHeading {
        font-size: 16px !important;
    }

    .s-mobile-profile-text {
        font-size: 15px !important;
    }

    .s-mobile-main-h {
        font-size: 18px !important;
    }

    .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label,
    .s-mobile-text-14 {
        font-size: 14px !important;
    }

    #profile .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-size: 16px !important;
    }

    #slot .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-size: 16px !important;
    }

    .s-mobile-align-start {
        align-items: flex-start !important;
    }

    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
    .MuiMenuItem-root {
        white-space: normal !important;
        font-size: 14px !important;
        min-height: 40px !important;
    }

    .blue-mobile-color {
        background-color: #cdeffe !important;
        border-radius: 10px !important;
    }

    .odd-grey:nth-child(even) {
        background-color: #ebebeb !important;
        margin-left: 0px !important;
        border-radius: 5px !important;
        padding-right: 0px !important;
        align-items: flex-start !important;
        margin-right: 0px !important;

    }

    .odd-grey:nth-child(odd) {
        margin-left: 0px !important;
        border-radius: 5px !important;
        padding-right: 0px !important;
        align-items: flex-start !important;
        margin-right: 0px !important;
    }

    .MuiFormControlLabel-labelPlacementEnd {
        align-items: center !important;
    }

    
    .MuiFormControlLabel-label {
        padding: 5px !important;
        word-break: break-all !important;
    }
    .removeWordbreak>div>label>span{
        word-break: normal !important;
    }
    .update>.MuiFormControlLabel-label{
        word-break: normal !important;
    }

    /* .MuiButtonBase-root {
        padding: 9px !important;
    } */

    .profilePageDropdownCorrection{
        padding: 9px !important;
    }

    .s-mobile-border {
        border-right: 1px solid grey !important;
        padding-right: 5%;
        margin-right: 5%;
    }

    .correctText>.MuiButtonBase-root {
        /* padding: 0px!important; */
    }

    .s-mobile-transform {
        transform: translate(0px, 0px) !important;
    }

    .s-mobile-delete-btn-i-profile {
        padding: 0px !important;
        /* padding-right: 0px!important; */
        border: none !important;
        transform: translateY(-7px) !important;
    }

    .s-mobile-sticky-btn-i-profile {
        position: sticky;
        top: 0px;
    }

    .s-margin-top-0-7 {
        margin-top: 0.7rem !important;
    }

    .s-mobile-transform-6 {
        transform: translateX(-6px);
    }

    .s-desktop-card-remove-margin {
        margin: 0px auto !important;
    }


    .s-mobile-changing-chip-property-day-wise,
    .s-mobile-changing-chip-property-day-wise>span {
        height: auto !important;
        white-space: pre-wrap !important;
    }

    .s-mobile-changing-chip-property-day-wise {
        padding: 10px 15px !important;
        margin: 0.2rem 0rem;
    }

    .s-mobile-one-word-per-line {
        width: 33.33% !important;
        word-spacing: 100px !important;
    }

    .s-mobile-padding-tab {
        padding: 24px 0.5rem !important;
    }


}

.s-desktop-card-remove-margin-any-view {
    margin: 0px auto !important;
}

.s-desktop-card-remove-margin-any-view-resume {
    margin: 0px auto !important;
    margin-top: 10px !important;
}

.s-margin-0 {
    margin: 0px !important;
}