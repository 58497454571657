.s-mobile-top-margin-1-5 {
    margin-top: 3rem !important;
}



.discount-name {
    font-size: 2rem;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    animation: gradient 2s ease infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@media screen and (min-width:1024px) {
    .discount-name{
        font-size: 24px!important;
    }
}



/* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite; */