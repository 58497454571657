.dashboard {
    margin: 10px auto;
    max-width: 1100px;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
    position: relative;
}

#profile-picture {
    position: relative;
    height: 200px;
    width: 200px;
    margin: 0 auto;
    z-index: 20;
}
#profile-picture img {
    padding: 6px;
    border: 3px solid #1c2e4a;
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

#profile-cover {
    position: absolute;
    top: 25px;
    z-index: 0;
    height: 150px;
    width: 100%;
    background-image: url('../../images/cover2.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    opacity: 0.8;
}

#profile-cover img {
    object-fit: cover;
}

#profile-cont {
    grid-template-columns: 70% 30%;
}

#profile-bio {
    grid-template-columns: 25% 58% 17%;
}


/* #social-links{
    height: 50px;
}

.fa-brands{
    transition: 0.1s linear font-size;
}

#profile-pref{
    width: 200px;
}

#profile-pref ul{
    list-style: disc;
}

.update-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 18px;
    color: blue;
}

.update-btn:hover {
    color: red;
}

.update-btn:active {
    color: blue;
}

.desc-pref{
    position: relative;
}

.edit-btn{
    margin-left: 10px;
    font-size: 18px;
    color: blue;
}

.edit-btn:hover{
    color: red;
}

.edit-btn:active{
    color: blue;
}


.dashboard .head {
    text-align: center;
    color: #1265a8;
    margin-bottom: 20px;

}


.bio {
    display: grid;
    grid-template-columns: 30% 70%;
    margin-top: 10px 20px;

}

.dashboard-info {
    text-align: center;
    margin-top: 50px;
}

.dashboard .profile-photo {
    width: 100%;
    margin-bottom: 20px;
}

.dashboard .profile-photo img {
    width: 60%;
    padding: 10px;
    border: 2px solid #1265a8;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 20%;
}

.dashboard-info h2 {
    color: #1265a8;
}

.dashboard-info h5 {
    font-weight: 400;
}


.resume-btn a:hover {
    background-color: white;
    color: blue;
}

.resume-btn{
    margin: 5px 0 15px;
}

.resume-btn a {
    border: 1px solid #1265a8;
    background-color: #1265a8;
    border-radius: 8px;
    color: white;
    text-decoration: none;
    padding: 8px 16px;
}

.dashboard-desc {
    padding: 0 20px;
    border-left: 1px solid grey;
}

.desc-head {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    color: #000;
}

.desc-item {
    margin-left: 30px;
    text-align: justify;
}

.dashboard-btns {
    padding: 20px 20px 0;
}

.dashboard-btn {
    display: block;
    border: 2px solid #1265a8;
    background-color: #1265a8;
    color: white;
    padding: 8px 16px;
    margin: 10px auto;
    width: 50%;
    text-align: center;
}

.dashboard-btn:hover {
    background-color: white;
    color: #1265a8;
}

.dashboard-btns .cont {
    text-align: center;
    margin: 0;
}


.profile-pref-search{
    width: 200px;
}


.desc-pref-edit{
    display: none;
    position: absolute;
    background-color: white;
    z-index: 10;
    border: 1px solid black;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 300px;
}

.desc-cross{
    position: absolute;
    top: 4px;
    right: 5px;
    cursor: pointer;
}

.desc-cross:hover{
    color: red;
} */