.navbar {
    justify-content: space-between;
    align-items: center;
    padding: 12px 40px;
    background-color: white;
    color: black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: fixed !important;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 74px;
    padding: 5px 20px !important;
    z-index: 16;
    transition: all 0.2s ease;
}

.tabs {
    margin-right: 30px;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
}

.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left !important;
}

.active {
    padding-bottom: 4px;
}

.active:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.alogo {
    text-decoration: none;
}

.tags {
    color: white;
    text-decoration: none;
}

.tags:hover {
    color: white;
}

.logo h1 {
    color: #02A3E8;
    font-size: 32px;
}

.nav-list {
    list-style-type: none;
    grid-gap: 0px;
    margin: 0;
}

.nav-item {
    font-size: 18px;
    cursor: pointer;
}

.nav-item * {
    color: black;
    text-decoration: none;
}

.nav-item *:hover {
    color: #0087ca;
}

.sidebar {
    position: relative;
    z-index: 10;
}

.notifier {
    position: relative;
}

.dot {
    position: absolute;
    top: 0;
    right: 8px;
    font-size: 14px;
    padding: 2px;
    text-align: center;
    color: white;
    border-radius: 10px;
    background-color: red;
}

.login-btn {
    border: 1px solid black;
    padding: 6px 12px;
    border-radius: 18px;
    background-color: rgb(0, 0, 0);
    color: white;
    font-size: 18px;
    margin-right: 16px;
}

.login-btn:hover {
    color: black;
    background-color: white;
}

.fa-bars {
    transition: 0.5s linear;
    font-size: 30px;
}

.fa-bars:hover {
    transform: rotate(90deg);
}

.active {
    color: rgb(18, 154, 18);
}

.active:hover {
    color: rgb(18, 154, 18);
}

.hide {
    display: none;
}

.dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    list-style: none;
    font-size: 18px;
    text-align: right;
    width: 190px;
    border-radius: 8px;
    overflow: hidden;
}

.dropdown .nav-item {
    display: none;
}

.dropdown li {
    background-color: #0fb5ff;
    padding: 2px 4px;
    cursor: pointer;
}

.dropdown li:hover {
    background-color: black;
}

.hide-ele-1,
.hide-ele-2,
.hide-ele-3 {
    display: none !important;
    min-height: 40px !important;
    border-bottom: 1px solid #d4d4d4 !important;
}

.remove-border-bt {
    border-bottom: none !important;
}

.navDropdown,
.navDropdownIcon {
    display: none !important;
}

.navDropdown>.MuiPaper-root {
    transform: translate(7px, 10px) !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.hidden {
    transition: all 0.2s ease;
    transform: translateY(-74px);
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1185px) {
    .hidden-success {
        display: none !important;
    }

    /* .nav-list {
        display: none;
    } */
    .navDropdown {
        display: initial !important;
    }

    .navDropdownIcon {
        height: 40px;
        width: 40px;
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .dropdown .nav-item {
        display: block;
    }

    .hide-ele-2 {
        display: block !important;
        border-bottom: none !important;
    }
}

@media screen and (max-width: 1080px) {
    .hidden-howitworks {
        display: none !important;
    }

    /* .nav-list {
        display: none;
    } */
    .navDropdown {
        display: initial !important;
    }

    .navDropdownIcon {
        height: 40px;
        width: 40px;
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .dropdown .nav-item {
        display: block;
    }

    .hide-ele-3 {
        display: block !important;
        border-bottom: none;
    }
}

.s-mobile-900 {
    display: none !important;
}

.s-desktop-900 {
    display: block !important;
}

@media only screen and (max-width: 900px) {
    .s-mobile-900 {
        display: flex !important;
    }

    .s-mobile-padding-900>div {
        padding: 5px !important;
    }

    .s-desktop-900 {
        display: none !important;
    }

    .nav-list {
        display: none !important;
    }

    .navDropdown {
        display: initial !important;
    }

    .navDropdownIcon {
        height: 40px;
        width: 40px;
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .dropdown .nav-item {
        display: block;
    }

    .hide-ele-1,
    .hide-ele-2,
    .hide-ele-3 {
        display: block !important;
    }

    .hide-ele-2 {
        border-bottom: 1px solid #d4d4d4 !important;
    }
}

@media only screen and (max-width: 550px) {
    .navbar {
        padding: 10px 20px;
    }

    .logo h1 {
        font-size: 28px;
    }

    .sidebar * {
        font-size: 22px;
    }

    .login-btn {
        font-size: 14px;
    }

    .dot {
        font-size: 10px;
    }

    /* .carousel{
    height: 200px;
  } */
}

@media only screen and (max-width: 400px) {
    .navbar {
        padding: 10px 18px;
    }

    .logo h1 {
        font-size: 26px;
    }

    .sidebar>* {
        font-size: 20px;
        /* padding: 0 14px; */
    }

    .login-btn {
        font-size: 12px;
    }

    .dot {
        font-size: 8px;
    }
}