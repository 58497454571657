.pricing {
    text-align: center;
}

.pricing-cont {
    width: 90vw;
    height: 80vh;
    background-color: white;
    margin: 0 auto;
    border-radius: 8px;
    margin-top: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.pricing-cont h2 {
    padding: 80px 0 50px;
    font-weight: 500;
    font-size: 40px;
}

.pricing-plan {
    /* height: 100%; */
    justify-content: space-evenly;
}

.plan-item {
    border: 1px solid rgb(175, 175, 175);
    padding: 10px;
    border-radius: 8px;
    margin: 20px 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 250px;
}

.plan-item ul li {
    text-align: left;
}

.plan-type {
    font-weight: 500;
    font-size: 20px;
}

.plan-price {
    font-weight: 700;
    font-size: 24px;
}

.plan-btn {
    border: 2px solid rgb(0, 187, 255);
    background-color: rgb(0, 187, 255);
    color: white;
    padding: 8px 16px;
    transition: 0.2s linear;
}

.plan-btn:hover {
    border: 2px solid rgb(0, 187, 255);
    background-color: white;
    color: rgb(0, 187, 255);
}

.pricing-img {
    width: "100%";
    padding: 1vw 1vw;
}

@media screen and (max-width: 768px) {
    .pricing-img {
        width: 75%;
        margin: 10px auto 40px auto;
    }
}