.privacyHead {
    font-size: 33px;
    text-align: center;
    font-weight: 700;
    padding-bottom: 20px;
    color: black!important;
}

.privacySubHead {
    font-size: 18px;
    padding-top: 10px;
    font-weight: 600;
}

.privacyText {
    font-size: 12px;
    padding: 10px 0;
}

.privacyWrapper {
    padding: 30px 10%;
}