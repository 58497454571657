.request-accepted-btn{
    display: block;
    border: 1px solid #00baf2;
    background-color: #00baf2;
    color: white;
    padding: 4px 8px;
}

.request-accepted-btn:hover{
    color: #00baf2;
    background-color: white;
}

.accepted-request .sent-item-btns{
    justify-content: center;
}

#declined{
    display: none;
}

#sub-section:hover ~ #declined{
    display: block;
}