.site-footer {
    display: flex;
    flex-direction: column;
    background-color: #26272b;
    padding: 45px 0 20px;
    font-size: 14px;
    line-height: 24px;
    color: #737373;
    padding: 50px;
    padding-bottom: 20px;
    font-family: "Ubuntu", sans-serif;
}

.container {
    margin: auto;
    position: static !important;
}

.footer__body,
.footer__foot {
    width: 100% !important;
}

.footer__body .row {
    display: flex;
    flex-direction: column;
}

.footer__bodyMid {
    display: flex;
    justify-content: space-between;
}

.site-footer hr {
    border-top-color: #bbb;
    opacity: 0.5;
}

.site-footer hr.small {
    margin: 20px 0;
}

.site-footer h6 {
    color: #fff;
    font-size: 16px;
    margin-top: 5px;
    letter-spacing: 2px;
}

.site-footer a {
    color: #737373;
    text-decoration: none !important;
}

.site-footer a:hover {
    color: #3366cc;
}

.footer-links {
    padding-left: 0;
    list-style: none;
}

.footer-links li {
    display: block;
}

.footer-links a {
    color: rgb(235, 235, 235);
    cursor: pointer;
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
    color: rgb(0 178 255) !important;
}

.footer-links.inline li {
    display: inline-block;
}

.site-footer .social-icons {
    text-align: right;
}

.footer-links-m *,
.footer-links-m {
    /* height: inherit; */
    display: block;
}

.site-footer .social-icons a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-left: 6px;
    margin-right: 0;
    border-radius: 100%;
    color: white;
    background-color: #33353d;
}

.copyright-text {
    margin: 0;
}

@media (max-width: 991px) {
    .site-footer [class^="col-"] {
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .site-footer {
        padding: 20px;
        padding-bottom: 0;
        padding-top: 50px;
    }
    .site-footer .copyright-text,
    .site-footer .social-icons {
        text-align: center;
    }
}

.social-icons {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.social-icons li {
    display: inline-block;
    margin-bottom: 4px;
}

.social-icons li.title {
    margin-right: 15px;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    font-size: 13px;
}

.social-icons a {
    background-color: #eceeef;
    color: white;
    font-size: 16px;
    display: inline-block;
    line-height: 44px;
    width: 44px;
    height: 44px;
    text-align: center;
    margin-right: 8px;
    border-radius: 100%;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
    color: #fff;
    background-color: #29aafe;
}

.social-icons.size-sm a {
    line-height: 34px;
    height: 34px;
    width: 34px;
    font-size: 14px;
}

.social-icons a.facebook:hover {
    background-color: #3b5998;
}

.social-icons a.twitter:hover {
    background-color: #00aced;
}

.social-icons a.linkedin:hover {
    background-color: #007bb6;
}

.social-icons a.dribbble:hover {
    background-color: #ea4c89;
}

.mobile-hedder {
    display: none;
}

@media (max-width: 800px) {
    .footer__bodyMid {
        flex-direction: column;
        display: none;
    }
    .mobile-hedder {
        display: block;
    }
}

@media (max-width: 767px) {
    .social-icons li.title {
        display: block;
        margin-right: 0;
        font-weight: 600;
    }
}

@media (max-width: 656px) {
    .footer__logo>div>h3 {
        font-size: 25px !important;
    }
    .footer__logo>div>p {
        margin-left: 0 !important;
        font-size: 12px !important;
    }
}