.login-container {
    position: fixed;
    z-index: 15;
    height: 100vh;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}

.login-popup {
    position: fixed;
    z-index: 15;
    height: 100vh;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}

.hide {
    display: none;
}

.login {
    background-color: white;
    position: relative;
    border-radius: 8px;
    /* max-width: 836px; */
}

.cross {
    position: absolute;
    right: -20px;
    top: -40px;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.cross:hover {
    color: rgb(255, 0, 0);
}

.login-head {
    background-color: #EFFFF4;
    color: #008A45;
    width: 100%;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    padding: 17px 40px;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
}

.login-system {
    padding: 40px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.col {
    padding: 5px 20px;
    border: 1px solid #c6c6c6;
    border-radius: 10px;
    min-width: 275px;
    /* margin: 10px 20px !important; */
}

.vertical-line {
    border-right: 1px solid grey;
}

.login-system h1 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    padding: 5px 0 20px;
    margin-bottom: 40px;
    text-align: center;
}

.signin-btn {
    width: 100%;
    border: 1px solid #D9D9DB;
    font-family: 'Ubuntu', sans-serif;
    background-color: white;
    color: black;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    padding: 12px;
    border-radius: 5px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signin-btn:hover {
    background-color: #dbdbdb;
}

.signin-btn img {
    width: 14px;
    margin-right: 10px;
}

.login-image {
    text-align: right;
}

.login-image img {
    height: auto;
}

@media only screen and (max-width: 730px) {
    .login-image {
        /* display: none; */
        opacity: 0;
    }
    .login-head {
        display: none;
    }
    .login-head-2 {
        display: block;
    }
    .cross {
        right: 10px;
        top: 10px;
        color: black;
    }
}

@media only screen and (max-width: 992px) {
    .login-image {
        display: none;
    }
    /* .login-head {
        display: none;
    } */
    .cross {
        right: 10px;
        top: 10px;
        color: black;
    }
}