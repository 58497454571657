.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 5%;
    background-color: white;
}

.contact-us-main-box {
    max-width: 1100px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.contact-us-img-box {
    float: right;
    width: 40%;
    margin: 0px 0px 15px 15px;
}

.contact-us-main-box-2 {
    border-radius: 10px;
    min-width: 1100px;
    padding: 10px;
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    background-color: rgb(187 247 208);
    overflow: hidden;
}

.ourteam-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 15px;
    padding: 10px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.team-details {
    width: 50%;
}

@media screen and (max-width: 1120px) {
    .contact-us-main-box-2 {
        min-width: 98%;
    }
}

@media screen and (max-width: 768px) {
    .contact-us-img-box {
        float: none;
        width: 80%;
        margin: 15px auto;
        /* margin: 0px 0px 15px 0px; */
    }
}

@media screen and (max-width :580px) {
    .ourteam-cards {
        flex-direction: column;
    }
    .ourteam-cards-reverse {
        flex-direction: column-reverse;
    }
    .team-details {
        width: 90%;
    }
    .namePost {
        width: fit-content;
        margin: auto;
        white-space: nowrap;
    }
    .namePost>h5 {
        font-size: 1.3rem;
    }
}