.owner__messages {
    align-self: flex-start;
    margin-bottom: 10px;

}

.peer__messages {
    align-self: flex-end;
    text-align: right;
    margin-bottom: 15px;
    
}

.message__name {
    font-size: 8px;
    color: white;
    margin-bottom: 2px;
}

.peer__messages > .message__text {
    border-radius: 10px 10px 0px 10px;
    background: hsl(198deg 98.29% 80.88%);
    box-shadow: inset -2px -2px 4px hsl(198deg 98.29% 63.88%);
    text-align: left;
}

.owner__messages > .message__text {
    border-radius: 10px 10px 10px 0px;
    border-radius: 10px 10px 10px 0px;
    background: pink;
    box-shadow: inset 2px -2px 4px hsl(349.52deg 100% 75.65%);
    text-align: left;

}

.message__text {
    font-size: 15px;
    background-color: whitesmoke;
    padding: 5px 7px;
    word-wrap: break-word;
    max-width: 300px;
    text-decoration: none;
    color: black;
}

.download__button {

    background-color: black;
    color: white;
    padding: 2px;
    border-radius: 10px;
    margin-right: 5px;
}