.notAuthenticated{
    display: grid;
    place-items: center;
    min-width: 100vw;
    min-height: calc(100vh - 70px);
}

.notAuthenticated > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    text-align: center;
}

.notAuthenticated > div > p{
    font-size: 20px;
    margin-bottom: 25px;
}