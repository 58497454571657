#contact-us-container {
    width: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#contact-us {
    max-width: 1100px;
    margin: 15px;
    margin-top: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


/* #contact-us-cover {
    background: rgb(134, 145, 245);
    background: linear-gradient(45deg, rgba(134, 145, 245, 1) 0%, rgba(100, 96, 245, 1) 100%);
    transform: rotate(-5deg) scale(1.2);
    width: 150vw;
    height: 50%;
    transform-origin: center;
} */


/* #linkedin-icon {
    background-color: #0A66C2;
}

#linkedin-icon:hover {
    background-color: #0a4e92;
} */

@media screen and (max-width: 768px) {
    #contact-us {
        flex-direction: column;
    }
}