.navbarlogedin {
    min-height: 74px;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 40px; */
    background-color: white;
    color: blaCK;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 0 20px;
    z-index: 100;
    transition: 0.4s linear top;
    transition: all 0.2s ease;
}

.logo h1 {
    color: #02A3E8;
    font-size: 2rem;
}

.nav-item * {
    color: black !important;
}

.nav-list {
    list-style-type: none;
    margin: 0;
    grid-gap: 0;
}

.navbarlogedin .nav-item {
    display: grid;
    place-items: center;
    font-size: 1rem;
    font-weight: 500;
    transition: 0.2s ease-out font-size;
    /* border: 1px solid black; */
    text-decoration: none !important;
    padding: 25px 10px;
    text-align: center;
    padding-bottom: 21px;
    /* border-bottom: 4px solid white; */
    transition: all 0.5s;
}

.navbarlogedin .active:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(1);
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
}

.navbarlogedin .active {
    background-color: rgb(196, 240, 255) !important;
    /* border-bottom: 4px solid rgb(57, 173, 212); */
    /* font-weight: 500;
    font-size: 20px; */
}

.navbarlogedin .nav-item:hover {
    /* color: #1265a8; */
    /* background-color: #e0ffff; */
    /* font-size: 1.3rem;
    font-weight: 500; */
}

.nav-item p {
    margin: 0;
    text-align: center;
}

.nav-item p:hover {
    color: red;
}

.notify {
    display: inline-block;
    background-color: red;
    text-align: center;
    color: white;
    height: 8px;
    width: 8px;
    margin-left: 4px;
    /* padding: 2px 7px; */
    border-radius: 50%;
}

.sidebar {
    position: relative;
    z-index: 10;
}


/* #search-bar{
    font-size: 1.5rem;
    border
} */

.notification-dropdown {
    position: absolute;
    top: 60px;
    right: 40px;
    width: 300px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 10px 0;
    z-index: 30;
}

.notification-dropdown h4 {
    text-align: center;
}

.notification-dropdown hr {
    margin: 0 10px;
}

.notification-item {
    font-size: 14px;
    padding: 5px 10px;
    cursor: pointer;
}

.notification-item h6 {
    margin-bottom: 4px;
}

.notification-item:hover {
    background-color: rgba(0, 0, 0, 0.03);
}

.sidebar-profile {
    /* flex-direction: column; */
    height: 50px;
    /* width: 50px; */
}

.sidebar-profile-img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
}


/* .sidebar-profile-img:hover {
    opacity: 0.5;
} */

.sidebar-profile-img img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.profile-dropdown {
    position: absolute;
    top: 60px;
    right: 0;
    width: 300px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 10px;
    z-index: 30;
}

.profile-dropdown-head {
    grid-gap: 10px;
}

.profile-dropdown-head h5 {
    margin: 0;
}

.profile-dropdown img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
}

.profile-dropdown p {
    font-size: 16px;
}

.view-profile-btn {
    display: block;
    font-size: 16px;
    border: 2px solid #1265a8;
    width: 90%;
    margin: 14px auto;
    color: #1265a8;
}

.view-profile-btn:hover {
    background-color: #1265a8;
    color: white;
}

.dropdown-btn {
    cursor: pointer;
    padding: 4px 16px;
    border-radius: 8px;
}

.dropdown-btn:hover {
    background-color: #1265a8;
    color: white;
}

#dropdown-btn {
    cursor: pointer;
    padding: 4px 16px;
    border-radius: 8px;
}

#dropdown-btn:hover {
    background-color: #1265a8;
    color: white;
}

.dropdown-logout-btn {
    cursor: pointer;
    border-radius: 8px;
}

.logout-btn {
    padding: 4px 16px;
    width: 100%;
    font-size: 16px;
}

.logout-btn:hover {
    background-color: red;
    color: white;
}

.notifier {
    position: relative;
    /* padding: 0 16px; */
    cursor: pointer;
}

.dot {
    position: absolute;
    top: 4px;
    right: 8px;
    font-size: 14px;
    padding: 2px;
    text-align: center;
    color: white;
    border-radius: 10px;
    background-color: red;
}

.hide {
    display: none;
}

.dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    list-style: none;
    font-size: 18px;
    text-align: right;
    border-radius: 8px;
    overflow: hidden;
}

.dropdown .nav-item {
    display: none;
}

.dropdown li {
    background-color: black;
    color: white;
    padding: 2px 4px;
    cursor: pointer;
}

.dropdown li:hover {
    background-color: #4b7a82;
}

.navLoggedInDropdown,
.navLoggedInDropdownIcon {
    display: none !important;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
}

.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

@media screen and (max-width:600px) {
    .hover-underline-animation:after{
        width: 82%;
    margin-left:9%;
    }
}

.active {
    padding-bottom: 4px;
}

.active:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.hidden-login-s {
    transition: all 0.2s ease;
    transform: translateY(-78px);
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 900px) {
    .nav-list {
        display: none !important;
    }

    .navLoggedInDropdown,
    .navLoggedInDropdownIcon {
        display: initial !important;
    }

    .navLoggedInDropdownIcon {
        padding: 8px!important;
        border-radius: 10px !important;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        background-image: linear-gradient(to right, #02a3e8, #0083b0)!important;
    }

    .navLoggedInDropdownIcon>svg {
        color: white;
        font-size: 33px;
    }

    .dropdown .nav-item {
        display: block;
    }
}

@media only screen and (max-width: 550px) {
    .navbarlogedin {
        padding: 10px 20px;
    }

    .logo h1 {
        font-size: 28px;
    }

    .sidebar * {
        font-size: 22px;
    }

    .login-btn {
        font-size: 14px;
    }

    .dot {
        font-size: 10px;
    }

    /* .carousel{
    height: 200px;
  } */
}

@media only screen and (max-width: 400px) {
    .navbarlogedin {
        padding: 10px 18px;
    }

    .logo h1 {
        font-size: 26px;
    }

    .sidebar>* {
        font-size: 20px;
        /* padding: 0 14px; */
    }

    .login-btn {
        font-size: 12px;
    }

    .dot {
        font-size: 8px;
    }
}