@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;
    scroll-behavior: smooth;
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

body {
    max-width: 100%;
    overflow-x: hidden;
    background-color: rgb(235, 235, 235);
    /* background: url('./images/blue-gradient.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    /* background: radial-gradient(74.32% 74.32% at 58.89% 42.38%, #5DEBFF 0%, #0162FF 100%); */
    /* background: radial-gradient(74.32% 74.32% at 58.89% 42.38%, #BBDEFB 0%, #64B5F6 100%); */
    /* background: radial-gradient(74.32% 74.32% at 58.89% 42.38%, #E3F2FD 0%, #BBDEFB 100%); // Applied */
    /* background-image: radial-gradient(circle farthest-corner at 22.4% 21.7%, rgba(4, 189, 228, 1) 0%, rgba(2, 83, 185, 1) 100.2%); */
}

.flex {
    display: flex;
    /* flex-direction: row; */
    align-items: center;
}

.cust-btn {
    text-decoration: none;
    border: none;
    /* background-color: transparent; */
    cursor: pointer;
    border-radius: 8px;
}

.interviews {
    margin: 10px auto;
    max-width: 1100px;
    background-color: white;
    border-radius: 8px;
    position: relative;
    padding-top: 1px;
    padding-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    min-height: 85vh;
    margin-top: 17px;
}

.bookmark {
    background-color: rgb(235, 235, 235);
    color: #306bc9;
}


/* .bookmark:hover{
  background-color: white;
  color: #3b82f6;
} */

.view-profile-card-btn {
    display: block;
    border: 1px solid black;
    background-color: black;
    color: white;
    padding: 8px 6px;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 8px;
}

.box-shadow-request-card {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.box-shadow-cust {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.logo-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.slots-gradiant {
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(215, 225, 236, 1) 100%);
}

.box-shadow-1 {
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}


/* Custom Scroll */

.custom-scroll::-webkit-scrollbar {
    width: 10px;
    border-radius: 8px;
}

.custom-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background: #888;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media screen and (max-width: 1100px) {
    .interviews {
        margin: 10px;
        width: inherit;
        margin-top: 18px;
    }
}

@media screen and (max-width: 1170px) and (min-width: 900px) {
    .interviews {
        margin-top: 37px!important;
    }
}

@media screen and (max-width: 768px) {
    .interview-head {
        font-size: 1.1rem!important;
    }
}

@media screen and (max-width: 600px) {
    .s-mobile-card-shadow{
        box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 8px!important;
    }
    .s-mobile-chip-tooltop-dist>div>div{
        margin-top: 0px!important;
        margin-bottom: 0px!important;
    }
    .s-select-mobile>div{
        padding: 5px!important;
    }
}