.container{
    position: absolute;
    top: 10%;
    left: 23%;
    width: 50%;
}
    
.text{
    display: inline;
    width: 100%;
}
    
.read-or-hide{
    color: #4297dd;
    font-weight: 500;
    cursor: pointer;
}
.privacypolicy {
    font-size: 16px;
}
.privacypolicy :hover {
    cursor: pointer !important;
}
.completed-interview .sent-item-btns {
    justify-content: center;
}

.meeting-id{
    color: rgb(14, 123, 14);
    font-size: 18px;
    font-weight: 700;
    font-size: 18px;
    padding: 5px 8px;
    border-radius: 8px;
    left: 0px;
}

.ratedConfirm{
    color: rgb(22, 85, 153);
    border: 2px solid rgb(22, 85, 153);
    border-radius: 10px;
    padding: 0 2px;
}

.rate-btn{
    /* margin-top: 10px; */
}