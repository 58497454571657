/* @import './styles.css'; */

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body::-webkit-scrollbar {
    width: 12px !important;
}

body::-webkit-scrollbar-track {
    background-color: #DEE0E1;
}

body::-webkit-scrollbar-thumb {
    background-color: #26272B;
}

.page-heading-h1 {
    text-align: center;
    color: #664aa6;
    font-weight: 500;
    font-size: 3.5rem;
    /* margin: 40px 0px 30px 0px; */
    text-shadow: 5px 5px 5px #dcdcdc;
}

.heading1 {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.3rem!important;
    color: #222222!important;
}

.normalText {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.1rem!important;
    color: #333333!important;
    font-weight: 400!important;
    line-height: 2rem!important;
}

.MuiChip-root {
    padding-top: 5px!important;
    padding-bottom: 5px!important;
}

@media screen and (max-width: 600px) {
    .s-mobile-changing-chip-property{
        padding-top: 2px!important;
        padding-bottom: 2px!important;
    }
    .MuiSelect-select{
        padding: 5px!important;
    }
}

@media screen and (max-width: 768px) {
    .heading1 {
        font-size: 1.1rem!important;
    }
    .normalText {
        font-size: 1rem!important;
    }
}

@media screen and (max-width: 480px) {
    .page-heading-h1 {
        font-size: 2.5rem;
    }
}