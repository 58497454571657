#bookmark-container{
    width: 700px;
    max-height: 98vh;
    overflow-x: auto;
}

/* width */
.bookmark-container::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.bookmark-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
}

/* Handle */
.bookmark-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

/* Handle on hover */
.bookmark-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}