#share-cont{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.30);
    z-index: 10;
}
#share{
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50%);
}