.profile-form {
    margin: 20px auto 10px auto;
    max-width: 1100px;
    /* padding: 0px 10px; */
    position: relative;
    background-color: white;
    border-radius: 8px;
}

.profile-form h1 {
    margin-left: 0 auto;
    /* border: 2px solid orange; */
}
.filebase input[type="file" i]::-webkit-file-upload-button{
    border-radius: 50px;
    padding: 5px 15px;
    font-size: 10;
    font-weight: 500;
    border: none;
    background-color: #f5f3ff;
    /* color: #dfd1f9 */
    color: #6d27d9;
}
.profile-form-cont {
    /* display: grid;
    grid-template-columns: 70% 30%;
    grid-gap: 20px; */
}

.profile-form .name {
    width: 100%;
    grid-gap: 13px;
    margin: 10px 0;
}

.profile-form-text {
    padding: 0px 20px;
}

.element {
    width: 100%;
}
.choosefile {
    color: rgb(192, 29, 29);
    font-size: small;
    padding-top: 7px;
}
.element>label {
    color: #095a54;
    font-size: 18px;
    font-weight: 500;
}

.profile-form .name input {
    width: 100%;
    height: 56px;
    font-size: 18px;
    padding: 8px;
    outline: none;
}

.profile-form .email {
    width: 100%;
    grid-gap: 13px;
    margin: 10px 0;
}

.profile-form .email input {
    width: 100%;
    height: 56px;
    font-size: 18px;
    padding: 8px;
    outline: none;
}

.profile-form-text {
    /* padding-right: 20px; */
}

.profile-form-profile {
    text-align: center;
    border-left: 1px solid grey;
    padding: 0 20px;
}

.profile-form-profile img {
    margin: 0 auto;
    display: block;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
}

.profile-form-profile input {
    display: block;
    word-wrap: break-word;
    margin: 0 auto;
}

.institution {
    width: 100%;
    grid-gap: 20px;
    margin: 10px 0;
}

@media screen and (max-width: 600px) {
    .institution{
        grid-gap: 0.5rem;
        margin-bottom: 30px;
    }
}

.institution select {
    width: 100%;
    padding: 10px;
    font-size: 18px;
}

.branch {
    width: 100%;
    grid-gap: 20px;
    margin: 10px 0;
}

.branch select {
    width: 100%;
    padding: 10px;
    font-size: 18px;
}

.branch input {
    width: 100%;
    padding: 10px;
    font-size: 18px;
}

.project {
    width: 100%;
    grid-gap: 10px;
}

.project input {
    width: 100%;
    padding: 10px;
    font-size: 18px;
}

.preference input {
    margin-left: 10px;
    margin-right: 2px;
    padding: 0;
    width: 20px;
}

.preference input::placeholder {
    word-wrap: break-word;
}

#desc {
    width: 100%;
}

.profilepref {
    width: 100%;
    grid-gap: 10px;
    /* margin: 0 0 20px; */
}

.profilepref select {
    display: inline-block;
    width: 100%;
    padding: 10px;
    font-size: 18px;
}

.profile-pref-list {
    width: 100%;
    min-height: 56px;
}

.profile-pref-item {
    display: inline-block;
    border: 1px solid black;
    border-radius: 8px;
    padding: 1px 8px;
    color: rgb(23, 184, 23);
    margin: 3px 10px;
}

.profile-pref-item p {
    display: inline;
}

.propref-btn {
    color: blue;
}

.propref-btn:hover {
    color: red;
}

.propref-btn:active {
    color: blue;
}

.time-preference p {
    color: #095a54;
    font-size: 18px;
    font-weight: 500;
}

.time-preference input {
    margin-right: 10px;
}

.add-time-btn {
    display: block;
    padding: 8px 16px;
    border: 1px solid #095a54;
    background-color: #095a54;
    color: white;
    margin-top: 10px;
}

.add-time-btn:hover {
    color: #095a54;
    background-color: white;
}

.create-btn {
    display: block;
    margin: 10px auto;
    border: 2px solid #4bae20 !important;
    padding: 8px 16px;
    background-color: #4bae20 !important;
    color: white !important;
}

.create-btn:hover {
    background-color: white !important;
    color: #12bd45 !important;
}


.profile-photo {
    margin-bottom: 12px;
}


.cust-row {
    /* margin: 10px 0; */
    padding: 3px 0;
}


/* ------------ */

.multiselect {
    width: 200px;
}

.selectBox {
    position: relative;
}

.selectBox select {
    width: 100%;
}

.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#checkboxes {
    display: none;
    position: absolute;
    border: 1px #dadada solid;
    background-color: white;
}

#checkboxes label {
    display: block;
    width: 150px;
    margin: 2px 5px;
}

#checkboxes label:hover {
    background-color: #1e90ff;
}

.select h5 {
    margin-bottom: 10px;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    background-color: white;
    /* width: 400px; */
    height: 200px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    justify-content: center;
    padding: 20px;
}

.select {
    grid-gap: 20px;
    margin: 20px 0;
}

.time input {
    margin-left: 10px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.close-btn:hover {
    color: red;
}

.add-btn {
    border: 1px solid #095a54;
    background-color: #095a54;
    border-radius: 8px;
    padding: 8px 16px;
    color: white;
    display: block;
    margin: 16px auto;
}

.add-btn:hover {
    background-color: white;
    color: #095a54;
}


.pref-item ul {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width:1140px) {
    .profile-form{
        margin: 20px 10px 10px 10px;
    }
}

@media screen and (max-width: 768px) {
    .element > label {
        font-size: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .profile-form-text {
        padding: 0px 10px;
    }
    .s-mobile-rounded-10{
        border-radius: 10px!important;
        width: 100%!important;
    }
}