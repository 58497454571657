.feedback{
    margin-top: clamp(14vh,5vw,109px);
}

.top__header{
    display: flex;
    justify-content: center;
    color: rgb(91, 166, 251);
    align-items: center;
    flex-direction: column;
}

.questions{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* text-align: center; */
    font-size: clamp(1rem,4vw,20px);
    width: 100%;
}

.question{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: clamp(5px,4vw,30px) 0;
    width: 90%;
    max-width: 1000px;
}

.questions__radio{
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
}

.degree{
    display: flex;
    justify-content: space-between;
}

.box__text{
    width: 100%;
    min-height: 115px;
    margin-top: 20px;
    padding: 10px;
    /* overflow:visible; */
}

.box__div{
    max-height: 2000px;
}

.selected-details{
    font-weight: 600;
    border: 1px solid darkblue;
    box-shadow: 2px 2px 2px;
}