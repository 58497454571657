.interviewer-accepted .request-accepted-btn {
  display: block;
  margin: 10px 0;
  border: 1px solid violet;
  background-color: violet;
  color: white;
  padding: 4px 8px;
  margin-left: 10px;
}

.interviewer-accepted .request-accepted-btn:hover {
  color: violet;
  background-color: white;
}

.hover-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.hover-animation:after {
  content: "";
  position: absolute;
  width: 70%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 47px;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.stickynavbar {
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  z-index: 500;
}

.unacceptwrapper {
  display: flex;
}

.unacceptdropdown {
  margin-left: 0;
  margin-top: 20px;
  border: 1px solid red;
  padding: 5px;
  width: 40px;
}

.unacceptdropcontent {
  position: relative;
  width: 150px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgb(235, 235, 235);
}

.unacceptdropcontent>button {
  width: 130px;
  background-color: crimson;
  color: #ffffff;
}

.popupnew {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0%;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.close-iconnew {
  cursor: pointer;
  position: relative;
  right: -102.5%;
  margin-top: -7%;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

#cancel-btn:hover{
  display: block;
}

#cancel:hover > #cancel-btn {
  display: block;
}