.main-box {
    display: flex;
    gap: 2rem;
    padding: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset, 2px 2px 6px inset white;
    transition: 0.3s ease-in-out;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 1.5rem;
}


/* .main-box:hover {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    transition: 0.3s ease-in-out;
} */

.img-box {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset, 2px 2px 6px inset white;
    border: 1px solid;
    background-color: rgba(255, 255, 255, 0.8);
}

@media screen and (max-width: 768px) {
    .main-box {
        flex-direction: column;
    }
}