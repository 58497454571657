.car2 {
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    height: 125px;
    /* padding:10%; */
}

.headingh1 {
    font-size: 3.5rem;
    color: #664aa6;
    font-weight: 500;
    margin: 40px 0px 30px 0px;
    text-shadow: 5px 5px 5px #dcdcdc;
}

.item-class {
    height: 100%;
    width: 200px!important;
}


/* hr {
    border: 1px solid #664AA6;
    width:80%;
} */

.sponcer_bigimg1 {
    align-content: center;
    width: 100px;
    height: 100%;
    padding: 2px;
    object-fit: contain;
}

.sponcer_bigimg2 {
    align-content: center;
    width: 100px;
    margin-left: 100px;
    height: 100%;
    padding: 2px;
    object-fit: contain;
}

.sponcer_bigimg1:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative
}

.react-multi-carousel-track {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, transition
}

.react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(247, 244, 244, 0.2);
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer
}

.react-multiple-carousel__arrow:hover {
    background: rgba(0, 0, 0, 0.8)
}

.react-multiple-carousel__arrow::before {
    font-size: 20px;
    color: #fff;
    display: block;
    font-family: 'Ubuntu', sans-serif;
    text-align: center;
    z-index: 2;
    position: relative
}

.react-multiple-carousel__arrow:disabled {
    cursor: default;
    background: rgba(0, 0, 0, 0.5)
}

.react-multiple-carousel__arrow--left {
    left: calc(1% - 13px)
}

.react-multiple-carousel__arrow--left::before {
    content: "\e824"
}

.react-multiple-carousel__arrow--right {
    right: calc(1% + 1px)
}

.react-multiple-carousel__arrow--right::before {
    content: "\e825"
}

.react-multi-carousel-dot-list {
    position: absolute;
    /* bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    margin: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center */
}

.react-multi-carousel-dot button {
    display: inline-block;
    /* width: 40px;
    height: 5px;
    border-radius: 0;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    transition: background .5s;
    border-width: 0.5px;
    border-style: solid;
    border-color: grey;
    padding: 0;
    margin: 0;
    margin-right: 6px;
    outline: 0;
    cursor: pointer; */
}

@media only screen and (max-width: 1200px) {
    /*Tablets [601px -> 1200px]*/
    .car {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        z-index: 2;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(3.5% + 1px)
    }
}

@media only screen and (max-width: 600px) {
    .car {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        z-index: 2;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(3.5% + 1px)
    }
}

@media only screen and (max-width: 425px) {
    /*Small smartphones [325px -> 425px]*/
    .car {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        z-index: 2;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(3.5% + 1px)
    }
}

.react-multi-carousel-dot button:hover:active {
    background: #ff6c00
}

.react-multi-carousel-dot--active button {
    background: #ff6c00
}

.react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden
}

@media all and (-ms-high-contrast:none),
(-ms-high-contrast:active) {
    .react-multi-carousel-item {
        flex-shrink: 0 !important
    }
    .react-multi-carousel-track {
        overflow: visible !important
    }
}

.image-box,
.animate-companies-2>img,
.animate-companies-1>img {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    height: 100%;
    border-radius: 15px;
    /* margin: 0px 37px; */
    /* width: fit-content; */
}

.image-box img {
    /* width: 100%; */
    height: 100%;
    object-fit: cover;
}

.animate-companies-1 {
    animation: comp1 50s linear infinite;
}

.animate-companies-1:hover {
    animation-play-state: paused;
}

.animate-companies-2 {
    animation: comp2 60s linear infinite;
    animation-delay: 1s;
}

.animate-companies-2:hover {
    animation-play-state: paused;
}

@keyframes comp1 {
    0% {
        /* opacity: 0; */
        transform: translateX(100px);
    }
    100% {
        /* opacity: 1; */
        transform: translateX(-4900px);
    }
}

@keyframes comp2 {
    0% {
        transform: translateX(100px);
    }
    100% {
        transform: translateX(-5400px);
    }
}