.pdf-container {
    height: 100%;
    width: 100%;
    padding: 5px;
    background-color: #383838; 
    overflow-y: scroll;
    overflow-x: auto;
    position: relative;
    padding: 5 10px;
}

.lift-container {
    position: absolute;
    width: 50%;
    top: 0;
    left: 0;
    z-index: 499;
}

.pdf-document {
    width: fit-content;
    margin: 0 auto;
    /* display: flex;
    justify-content: center; */
}

.pdf-controls {
    zoom: 0.8;
    top: 0;
    left: 0;
    position: sticky;
    background-color: #727272;
    box-shadow: 0 3px 6px rgba(0,0,0,0.8);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 3px;
    color: white;
    z-index: 50;
}

.pdf-control {
    display: flex;
    justify-content: space-evenly;
    width: 20%;
    align-items: center;
}

.exit-pdf-controls {
    width: fit-content;
}

