.div-testimonial {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background: red; */
    padding-top: 80px;
}

.main-testimonial {
    width: clamp(572px, 75vw, 730px);
    height: 400px;
    display: flex;
    align-items: center;
    /* white-space: nowrap; */
    /* display: flex; */
    /* align-items: center; */
    position: relative;
}

@media screen and (max-width:580px) {
    .main-testimonial {
        width: 95%;
    }
}

.testimonial-div {
    min-width: 100%;
    height: 100%;
    background: hsl(2 97% 90% / 1);
    box-shadow: inset -4.18282px -4.18282px 4.18282px rgba(0, 0, 0, 0.25), inset 4.18282px 4.18282px 4.18282px rgba(255, 255, 255, 0.25), 10px 10px 30px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(27px);
    border-radius: 37.6453px;
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    flex-direction: column;
    padding: 0px 20px 20px 20px;
    /* display: inline-block; */
    /* white-space: normal; */
}

.iamge-border {
    position: absolute;
    width: fit-content;
    /* height: 105.62px; */
    padding: 10px;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    border-radius: 200px;
    margin-left: auto;
    margin-right: auto;
    background: rgb(255, 255, 255);
    box-shadow: inset 4.18282px 4.18282px 4.18282px rgba(255, 255, 255, 0.5), inset -4.18282px -4.18282px 4.18282px rgba(0, 0, 0, 0.26);
    backdrop-filter: blur(9px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.iamge-border>img {
    width: 100px;
    backdrop-filter: blur(9px);
    height: 100px;
    object-fit: cover;
    object-position: center center;
    border-radius: 200px;
    filter: drop-shadow(0px 0px 3.13711px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 10.457px rgba(0, 0, 0, 0.25));
}

.testimonial-details {
    text-align: center;
    margin-top: 80px;
    line-height: 124.2%;
    letter-spacing: 0.11em;
    color: #000000;
    padding: 0px 30px;
    width: 75%;
    /* text-shadow: 0px 0px 15.6856px rgba(0, 0, 0, 0.43), 0px 0px 4.18282px rgba(0, 0, 0, 0.25); */
}

.testimonial-details>p {
    /* font-family: sans-serif; */
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.testimonial-details>p>b {
    font-family: 'Ubuntu', sans-serif;
    font-style: italic;
}

.testimonial-comment {
    font-family: 'Varela Round', sans-serif;
    line-height: 146.9%;
    font-weight: bold;
    /* or 18px */
    margin: 10px 30px;
    text-align: justify;
    letter-spacing: 0.02em;
    color: #000000;
    /* text-shadow: 0px 4.18282px 4.18282px rgba(0, 0, 0, 0.25); */
    position: relative;
    text-overflow: clip;
}

.inverted1 {
    position: absolute;
    top: -30px;
    left: -30px;
}

.inverted2 {
    position: absolute;
    bottom: -30px;
    right: -30px;
}

.testimonial-name {
    font-family: 'Alkatra', cursive;
    /* font-style: italic; */
    color: #000000;
    font-weight: 400;
    font-size: 1.2rem;
    align-self: flex-end;
    margin-right: 20px;
    line-height: 109.48%;
    /* identical to box height, or 16px */
    letter-spacing: 0.02em;
}

.prev-btn {
    position: absolute!important;
    background: hsl(2 97% 90% / 1)!important;
    left: -60px;
    top: 46%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset, 10px 10px 30px rgba(0, 0, 0, 0.5);
}

.next-btn {
    position: absolute!important;
    background: hsl(2 97% 90% / 1)!important;
    right: -60px;
    /* height: 25px; */
    top: 46%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset, 10px 10px 30px rgba(0, 0, 0, 0.5);
}

.prev-btn-mobile,
.next-btn-mobile {
    margin: 20px 15px 0px 15px!important;
    background: hsl(2 97% 90% / 1)!important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset, 10px 10px 30px rgba(0, 0, 0, 0.5);
}

.show-mobile {
    display: none;
}

.allSuccessStoriesBtn {
    margin-top: 30px;
}

@media screen and (max-width:830px) {
    .main-testimonial {
        height: 500px;
    }
    .testimonial-details {
        width: 90%;
    }
}

@media screen and (max-width:679px) {
    .show-mobile {
        display: block;
    }
    .prev-btn {
        display: none!important;
    }
    .next-btn {
        display: none!important;
    }
}

@media screen and (max-width:500px) {
    .main-testimonial {
        height: 550px;
    }
    .testimonial-details {
        width: 90%;
    }
}

@media screen and (max-width:405px) {
    .main-testimonial {
        height: 600px;
    }
    .testimonial-details {
        width: 95%;
    }
}

@media screen and (max-width:390px) {
    .main-testimonial {
        height: 470px;
    }
    .testimonial-details {
        width: 95%;
    }
}