.welcome {
  /* width: 70%; */
  margin: auto;
  background-color: white;
  border-radius: 20px;
  padding: 10px;

}

.welcome>p {
  height: 400px;
  width: 70%;
  display: flex;
  line-height: 50px;
  font-size: 18px;
  border: 1px solid white;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 30px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .welcome>p {
    height: 650px;
    width: 100%;
    margin-top: 20px;
  }
}