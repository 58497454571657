.validator{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.validator__box{
    margin: 0.5rem;
    text-align: center;
    width: 500px;
    height: 300px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    background-color: white;
    border-radius: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.098);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.top__text{
    font-size: 22px;
}