.cancel-popup {
    visibility: hidden;
    position: fixed;
    z-index: 15;
    height: 100vh;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}

.cancel-popup-container{
    background-color: white;
    position: relative;
    border-radius: 8px;
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px;
    width: 300px;
    height: 120px;
    text-align: center;
}
.cancel-popup-container .btns{
    margin-top: 24px;
}

.btns button{
    margin: 0 10px;
    width: 50px;
    border-radius: 8px;
}

.btns button:hover{
    background-color: red;
    border-color: red;
    color: white;
}