.preferences {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 10px;
  margin-left: -50px;
}
.preferences > div > h5 {
  font-size: 15px;
}
.editdelete {
  display: flex;
  position: absolute;
  flex-direction: column;
  background-color: #f9f9f9;
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.buttons {
  display: flex;
  justify-content: space-around;
}
.buttons > button {
  background-color: antiquewhite;
}
.editdelete > div:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  background-color: rgb(255, 255, 255);
  font-size: 11px;
  color: rgb(83, 81, 81);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  width: 250px;
  bottom: 100%;
  left: 50%;
  margin-left: -125px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tt {
  position: relative;
  display: inline-block;
}

.tt .tttext {
  visibility: hidden;
  /* width: 120px; */
  background-color: rgb(255, 255, 255);
  font-size: 11px;
  color: rgb(83, 81, 81);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  width: 264px;
  top: -40px;
  right: 0%;
  background-color: black;
  color: white;
  padding: 3px 5px;
}

.tt:hover .tttext {
  visibility: visible;
}

.calendar-mobile-tooltip {
  background-color: white!important;
  top:0px!important; 
  padding:0px!important;
}


.delPopup {
  position: absolute;
  background-color: #f9f9f9;
  width: 200px;
  height: 60px;
  z-index: 10;
}
.alert-container {
  position: absolute;
  top: 0%;
  left: 0%;
}

.alert-inner {
  display: inline-block;
  padding: 8px 16px;
  z-index: 1;
  background-color: red;
  font-size: 13px;
  color: #ffffff;
  box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
  -webkit-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
  -moz-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
}
.exception {
  justify-content: space-around;
}
.exception > button {
  background-color: blueviolet;
  padding: 5px;
  border: 1px solid black;
}
.exdiv {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar {
  width: 800px;
  height: 600px;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

#time-picker select{
  padding: 4px;
  border: 1px solid black;
  border-radius: 4px;
}

.interviewerProfileFormPrevCompInput{
  width: 100%;
  display: flex;
  align-items: center;
}

.interviewerProfileFormPrevCompInput > .MuiButton-root{
  padding: 7px !important;
  min-width: 0;
  margin-left: 10px;
}

.interviewerProfileFormPrevCompInput > .MuiButton-root > .MuiSvgIcon-root{
  font-size: 40px;
}

.interviewerProfileFormPrevCompInput > input{
  flex: 1;
  padding: 10px;
  outline: none;
}
.s-rectify-margin{
  margin: 0px!important;
}