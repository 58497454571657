.interviews {
    max-width: 1100px;
    background-color: white;
    border-radius: 8px;
    position: relative;
}

#head {
    text-align: center;
    background-color: white;
    /* width: 100%; */
    /* margin-top: 30px; */
    padding: 10px;
    border-radius: 8px;
    z-index: 20;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.interviews-cont {
    /* display: grid;
    grid-template-columns: 70% 30%; */
}

.interviews-list {
    /* border-right: 1px solid grey; */
    /* padding: 0 20px; */
    max-width: 900px;
    margin: 0 auto;
}

.fullPageLoader {
    width: '100%';
    display: flex;
    align-items: center;
    justify-content: center;
}

.interview-item-container {
    padding: 10px;
    border: 2px solid #1265a8;
    margin: 10px 0;
    border-radius: 8px;
}

.interview-item {
    display: grid;
    grid-template-columns: 25% 75%;
    text-align: right;
    padding: 10px;
    border: 2px solid #1265a8;
    margin: 10px 0;
    border-radius: 8px;
}

.interviewer-head h3 {
    margin: 0;
    /* font-size: 36px */
}

.interviewer-head p {
    font-size: 20px;
}

.interview-item-profile {
    padding: 10px;
    height: 150px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.interview-item .time-preference {
    text-align: left;
}

.interview-item-profile img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    margin: auto;
    border: 2px solid black;
    /* padding: 10px; */
}

.interview-item-info {
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.interview-item-btns {
    justify-content: space-between;
}

.interviews .view-btn {
    /* display: inline-block; */
    border: 1px solid black;
    background-color: black;
    color: white;
    /* margin: 0 auto; */
    padding: 8px 6px;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
}

.sent-request .view-btn {
    margin-top: 10px;
}

.view-btn:hover {
    background-color: white;
    color: black;
}

.details {
    align-items: flex-start;
}

.details p {
    text-align: center;
}

.request-sent-btn {
    display: block;
    margin: 0;
    margin-left: auto;
    border: 1px solid rgb(87, 87, 87);
    background-color: rgb(87, 87, 87);
    color: white;
    padding: 4px 8px;
}


/* .request-sent-btn:hover{
    background-color: white;
    color: black;
} */


/* .request-sent-btn:after{
    content: "Request Sent";
} */


/* .request-sent-btn:hover:after{
    content: "Cancel Request";
} */

.request-sent-btn:active {
    color: white;
    background-color: red;
    border-color: red;
}

.interviews-else {
    padding: 10px 20px;
}

.search-result-item-btns {
    position: relative;
}

.hide {
    display: none;
}

.cancel-dropdown-cont {
    width: 10px;
    display: block;
}

.cancel-dropdown {
    width: 20px;
    height: 40px;
    border-radius: 8px;
    border: 2px solid rgba(128, 128, 128, 0.3);
    justify-content: center;
    cursor: pointer;
    margin-left: 2px;
    /* margin-right: 10px; */
}

.cancel-dropdown:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.cancel-btn {
    height: 40px;
    display: none;
    position: absolute;
    bottom: 2px;
    right: 74px;
    border: 2px solid red;
    padding: 4px 8px;
    border-radius: 8px;
    z-index: 5;
    background-color: red;
    color: white;
}

.cancel-btn:hover {
    display: block;
    border-color: rgb(216, 0, 0);
    background-color: rgb(216, 0, 0);
}

.cancel-dropdown-cont:hover>.cancel-btn {
    display: block;
}


/* Sent Request Item Card */

.sent-item {
    /* border: 2px solid #1265a8; */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    /* box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset; */
    /* border: 1px solid rgb(223, 223, 223); */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    /* box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em; */
    display: grid;
    grid-template-columns: 25% 75%;
    border-radius: 8px;
    /* padding: 10px; */
    /* transition: 1s; */
}

.sent-item:hover {
    /* transition: 1s;
    z-index: 100; */
}

.sent-item-profile {
    /* height: 200px; */
    border-right: 1px solid rgb(175, 175, 175);
    /* padding: 10px; */
}

.sent-item-profile img {
    height: 130px;
    border-radius: 50%;
    border: 2px solid black;
    padding: 3px;
    display: block;
    margin: 0 auto;
}

.sent-view-btn {
    display: block;
    border: 1px solid black;
    background-color: black;
    color: white;
    margin: 10px auto 0;
    padding: 8px 6px;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    width: 80%;
}

.sent-view-btn:hover {
    background-color: white;
    color: black;
}

.sent-item-head {
    text-align: Center;
    padding: 0 10px;
}

.sent-item .details {
    margin-top: 10px;
}

.sent-item-name {
    width: 100%;
    /* justify-content: flex-end; */
}

.sent-item-btns {
    position: relative;
}

#updated {
    /* display: none; */
}

.unshow {
    display: none;
}