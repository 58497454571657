.interviewend {
    height: 100vh;
    /* margin-top: 200px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    padding: 0px 20px;
}

.interviewend>a {
    align-self: center;
}

@media screen and (min-width: 700px) {
    .interviewend {
        align-items: center;
    }

    
}

@media screen and (max-width: 700px) {
    .student_end_mock {
        position: relative;
        transform: translate(66px, 22px);
    }
}