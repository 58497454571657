@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/* @media screen and (min-width:700px) {
  ::-webkit-scrollbar {
    width: 15px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;

  }

  ::-webkit-scrollbar-thumb {
    background: rgb(119 119 119);
    border-radius: 5px;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(148, 148, 148);
  }
} */

:root {
  --main-darklg: rgb(38, 38, 38);
  --main-dark: #121213;
  --primary-color: #e5e7ea;
  --main-light: #343333;
  font-family: "Poppins", sans-serif;
  --header-height: 7vh;
}

* {
  margin: 0;
  padding: 0;
}

.videoApp {
  position: absolute;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.header__desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--header-height);
  position: relative;
  width: 100%;
  background-color: var(--main-darklg);
  text-align: center;
}

.header__desktop>.logo-video {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */
  width: 100%;
}



.left__header-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  color: darkgrey;
  align-items: flex-start;
  padding: 0px 10px;
  font-size: 0.8rem;
  flex: 0.5;
  overflow-y: auto;
  height: 120px;
  /* flex: 2 1; */

}

.right__header__desktop {
  display: flex;
  flex: 0.5;
  /* flex-direction: column; */
  text-align: left;
  color: white;
  padding: 20px;
  justify-content: space-evenly;
  position: relative;
}

.center__text {
  display: flex;
  color: white;
}

.bell {
  position: relative;
  width: fit-content;

  /* margin: 3px 0 0 10px; */
  color: white;
}

.bell__point {
  position: absolute;
  width: 8px;
  height: 8px;
  border: 1px solid red;
  border-radius: 10px;
  background-color: red;
  right: 0;
  top: 8px;
}


.notification {
  position: absolute;
  z-index: 100;
  width: 300px;
  right: -30px;
  color: red;
  background-color: white;
  border: 1px solid red;
  padding: 5px 10px;
  border-radius: 20px;
  transform: scale(0.75);
}

.notification-mobile {
  position: absolute;
  z-index: 100;
  width: 131vw;
  right: -18vw;
  color: red;
  background-color: white;
  border: 1px solid red;
  padding: 5px 10px;
  border-radius: 20px;
  transform: scale(0.75);
}

.redline {
  border-bottom: 1px red solid;
  animation: blink1 1s infinite ease-in-out;
}

.blink {
  color: white;
  cursor: pointer !important;
  animation: blink2 0.5s infinite ease-in-out;
}

@keyframes blink2 {
  from {
    border: 1px solid black;
    background-color: black;
  }

  to {
    border: 1px solid red;
    background-color: red;
  }
}

@keyframes blink1 {
  from {
    border-bottom: 1px solid black;
  }

  to {
    border-bottom: 1px solid red;
  }
}

.s-desktop-video-details > .MuiBox-root  {
  color: white;
}

b {
  color:rgb(2, 163, 232);
}

.options__button-desktop  > svg {
  zoom: 0.8;
}

.main__desktop {
  overflow: auto;
  height: calc(100vh - var(--header-height));
  display: flex;
  position: relative;
}

.main__left {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  position: relative;
}

.videos__group {
  position: relative;
  flex-grow: 1;
  height: 75vh;
  display: flex;
  justify-content: center;
  /* justify-content: center; */
  align-items: center;
  /* padding: 1rem; */
  background-color: var(--main-dark);
  overflow: auto;
}

#video-grid {
  /* padding: 10px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center !important;
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

video {
  height: 300px;
  border-radius: 1rem;
  width: 500px;
  /* object-fit: cover; */
  transform: rotateY(180deg);
  transition-duration: 0.2s;
  transition-timing-function: linear;

  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

video:hover {
  /* position: absolute;
  top: 0;
  width: 80%;
  height: 80%; */
  border: solid 3px greenyellow;
  /* opacity: 0.5; */
}

/* // ------------------------------------------------------ */

.pin__video {
  justify-self: flex-start !important;
  width: 70%;
  height: 95%;
  margin: 0.5rem;
}

.unpinned__video-desktop {
  display: flex;
  flex-direction: column;
  /* flex: 0.65 !important; */
  /* width: 115px; */
  background-color: #3c3d3d;
  border-radius: 1rem;
  /* margin: 10px 0; */
  min-height: 90%;
  overflow-x: auto;
  transition: max-height,min-height 0.3s ease-out;
  max-height: 90%;
  position: absolute;
  top: 29px;
  right: 5%;
  z-index: 50;
}

.collapsed {
  position: absolute;
  right: 5%;
  top: 29px;
  overflow: hidden;
  max-height: 0;
  min-height: 0;
}

.unpinned__video-desktop video {
  width: 100px !important;
  height: 100px !important;
  margin: 0.5rem;
}

/* // ------------------------------------------------------ */
.video__click {
  position: absolute;
  width: 100%;
  height: 100%;
}

.options-desktop {
  position: sticky;
  bottom: 0;
  padding: 0.3rem 1rem;
  display: flex;
  background-color: var(--main-darklg);
  overflow-x: auto;
}

.options__left {
  display: flex;
}

.options__right {
  margin-left: auto;
}

.options__button-desktop  {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  height: 32px;
  border-radius: 5px;
  color: var(--main-light);
  font-size: 1.2rem;
  width: 32px;
  margin: 0 0.5rem;
  cursor: pointer;
}

.background__red {
  background-color: #f6484a;
}

.main__right-desktop {
  max-height: inherit;
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 0.3;
  background-color: #3c3d3d;
  transition: ease-out 1s linear;
}

.file__upload {
  position: absolute;
  display: flex;
  bottom: 0px;
  transform: translateY(-140%);
}

.main__chat_window-desktop {
  display: flex;
  flex-grow: 1;
  position: relative;
  /* overflow-y: scroll; */
}

#comment__cancel {
  margin-top: 5px;
  position: absolute;
  right: 3%;
  background-color: rgb(119 119 119);
  border-radius: 5px;
  padding: 0px 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.main__chat_window::-webkit-scrollbar {
  display: none;
}

.message__text {
  font-size: 15px;
  background-color: whitesmoke;
  padding: 5px 7px;
  border-radius: 10px;
  word-wrap: break-word;
  max-width: 300px;
  text-decoration: none;
  color: black;
}

.main__message_container-desktop {
  /* padding: 1rem; */
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11vh;
  background-color: #343333;
}

.main__message_container-desktop>textarea {
  /* height: 50px !important; */
  flex: 1;
  font-size: 1rem;
  border-radius: 5px;
  padding-left: 20px;
  border: none;
  padding-top: 12px;
  resize: none;
  outline: none;
  zoom: 0.8;
}

.main__message_container-desktop> textarea:focus {
  border-color: #007bff; /* change the border color on focus */
  box-shadow: 0 0 5px #007bff; /* add a box shadow on focus */
}

.messages-desktop {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 79vh;
  padding: 0rem 1rem;
  margin: 1rem 0rem 0rem 0rem;
  /* height: 67vh; */
  /* margin: 1.5rem; */
  overflow-y: scroll;
  position: relative;
  scrollbar-width: none;
}

.messages-after {
  position: sticky;
  top: 0px;
  height: 15px;
  width: -webkit-fill-available;
  content: "";
  background-color: red;
}

.messages-before {
  position: sticky;
  bottom: 0px;
  height: 15px;
  width: -webkit-fill-available;
  content: "";
  background-color: red;
}

.message {
  display: flex;
  flex-direction: column;
}


/* #video-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
} */

/* annotations */
/* annotations */

.board {
  /* background-color: white; */
  width: 99%;
  height: 96%;
  border-radius: 1rem;
  margin: 0.5rem;
  /* background-color: transparent !important; */
}

.sketch {
  width: 80%;
  height: 95%;
  background-color: transparent !important;
  position: absolute;
  z-index: 10000;
}

.show__sketch {
  display: none;
}

.annotation__bg {
  background-color: red;
  color: white;
}

.sketch__unpin {
  left: 80px;
}

/* annotations */
/* annotations */


.showChat {
  display: none;
}

.showMain {
  flex: 1;
}

.header__back {
  display: none;
  position: absolute;
  font-size: 1.3rem;
  top: 17px;
  left: 28px;
  color: #fff;
}

.tooltip {
  background-color: black;
  color: white;
  padding: 3px 5px;
  /* margin-bottom:5px; */
  border-radius: 10px;
  font-size: 10px;
  position: fixed;
  z-index: 9999;
}

.timer-desktop>span {
  margin: 0 2px;
}

.text__name {
  pointer-events: none;
  text-align: center;
  /* width: 100%; */
}



.text__centerpin {
  width: 100%;
}

.s-mobile-video-details__title>button:active,
.s-mobile-video-details__title>button:focus {
  background-color: white;
}

.text__unpin {
  position: relative;
  top: 83px;
  left: 83px;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 10rem;
  margin: -25px;
}

.text_screen_share{
  font-size: 10px !important;
  color: greenyellow !important;
  background-color: #343333;
  text-align: center;
  padding: 10px;
}

/* .text__unpin{
  position: relative;
  top: 70px;
  z-index: 1;
  font-size: 18px;
} */

.center__header__desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.text__pin {
  position: absolute;
  z-index: 1;
  font-size: 40px;
}

.s-mobile-video-details {
  display: none;
}

.s-mobile-video-details__title {
  display: none;
}

.s-mobile-bell,
.s-mobile-will-finishin {
  display: none;
}

.feedback__button-mobile {
  margin-right: 0px;
}

.close_details_mobile_vc {
  position: absolute;
  bottom: 0px;
  transform: translateY(100%);
  background-color: #800000d9;
  right: 10px;
  padding: 0.5rem;
  border-radius: 0px 0px 10px 10px;
  backdrop-filter: blur(10px);
}

#chat_message {
  height: 50px;
  margin-right: 0.5rem;
}

@media (max-width: 700px) {
  .text__pin {
    top: 30%;
  }

  #chat_message {
    height: 39.99px;
    margin-right: 0;
  }

  #send {
    margin-right: 0;
  }

  .main__left {
    width: 100%;
    flex: 1;
  }

  .main__right-desktop {
    position: absolute;
    width: -webkit-fill-available;
    z-index: 1;
    /* height: 74.82vh; */
  }

  .messages-desktop {
    height: 62vh;
    padding-top: 20px;
  }

  video {
    height: auto;
    width: 100%;
  }

  #showChat {
    display: flex;
  }

  .pin__video {
    height: 70%;
    width: 95%;
  }

  /* .unpinned__video {
    flex-direction: row;
    min-height: auto;
  } */

  /* .options__button-desktop  {
    width: 20px;
    height: 20px;
  } */

  button.send-btn {
    width: 40px;
    height: 40px;
    transition: background-color 0.2s ease-in-out;
  }

  button.send-btn:hover {
    background-color: #0062cc;
  }
  
  button.send-btn:active {
    animation: send-button-click 0.2s ease-in-out;
  }
  
  @keyframes send-button-click {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-2px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .s-desktop-bell {
    display: none;
  }

  .s-mobile-bell {
    display: flex;
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 1;
  }

  /* .s-mobile-will-finishin {
    display: flex;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    justify-content: center;
    z-index: 1;
  } */

  /* .right__header {
    padding: 5px;
  } */

  .timer-desktop {
    display: flex;
    justify-content: center;
    font-size: 14px;
    align-items: center;
    height: 100%;
  }

  /* .options__button-desktop {
    font-size: 14px;
  } */
  .feedback__button-mobile {
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-weight: 600;
  }

  .feedback__button-mobile>button {
    font-size: 12px !important;
    font-weight: 600;
  }

  /* .tt {
    position: absolute;
  } */

  .tt .tttext_vc {
    visibility: hidden;
    /* width: 120px; */
    background-color: rgb(255, 255, 255);
    font-size: 20px;
    color: rgb(83, 81, 81);
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    width: 130vw;
    top: -13px;
    right: -17.7vw;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: red;
    height: 16vh;
    padding: 3px 5px;
  }

  .tt:hover .tttext_vc {
    visibility: visible;
  }

  .s-mobile-btn-vc {
    white-space: nowrap !important;
    /* padding: 0px 5px !important; */
    position: absolute !important;
    bottom: 5px !important;
    right: 5px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }
}

@media screen and (max-width:1000px) {
  .s-desktop-video-details {
    display: none;
  }

  .s-mobile-video-details {
    display: flex;
  }

  .s-mobile-video-details__title {
    /* font-size: 1.2rem; */
    flex-direction: column;
    display: flex;
    position: absolute;
    top: 0px;

    position: absolute;
    width: -webkit-fill-available;
    text-align: left;
    z-index: 2;
    color: #e3e3e3;
    background: #800000d9;
    padding: 0.3rem;
    border-radius: 0px 0px 10px 10px;
    backdrop-filter: blur(10px);
  }

  .s-mobile-video-details__title>button {
    background: rgba(255, 255, 255, 0.8);
    color: black;
    font-weight: 600;
  }
}


.hide {
  display: none;
}

@media screen and ( max-width: 600px ) {
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 5px !important;
    font-size: 14px !important;
    text-align: left !important;
  }
}
