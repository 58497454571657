p {
    margin: 0;
}

.hover-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
}

.hover-animation:after {
    content: "";
    position: absolute;
    width: 70%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 47px;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.stickynavbar {
    position: sticky;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: #fff;
    z-index: 500;
}


#stack-cont{
    height: 100%;
    transition: all 1s linear;
}

#stack-ext{
    transition: all 1s linear;
}

.stack-hide{
    height: 0px;
    overflow: hidden;
    /* transition: all 1s linear; */
    /* display: none; */
    /* height: 0; */
}
.stack-show{
    height: 1047px;
    overflow: unset;
    /* display: unset; */
    /* height: auto; */
    /* transition: all 1s linear; */
}

/* #stack-container{
    display: none;
    height: auto;
    transition: all 1s linear;
} */

.stack-hide{
    /* max-height: 0px; */
    /* animation: hide 1s linear;
    overflow: hidden;
    display: none; */
}

/* .stack-show{
    animation: show 1s linear;
    overflow: unset;
    display: unset;

}

#stack-cont{
    max-height: 100%;
    transition: all 1s linear;
} */

/* @keyframes show{
    0% {
            transform: scaleY(0);
        }
    
        10% {
            transform: scaleY(0.1);
        }
    
        100% {
            transform: scaleY(1);
        }
}
@keyframes hide{
    0% {
            transform: scaleY(1);
        }
    
        90% {
            transform: scaleY(0.1);
        }
    
        100% {
            transform: scaleY(0);
        }
} */

/* ---------- Ignore -------------- */

.received-request4{
    transform-origin: 1px 1px;
    transform: translate(0px, 0px);
    transform: scale(0);
    animation: load4 0.1s linear forwards;
}
.received-request3 {
    transform-origin: 5px 5px;
    transform: translate(8px, 8px);
    transform: scale(0);
    animation: load3 0.5s 0.5s linear forwards;
}
.received-request2 {
    transform-origin: 9px 9px;
    transform: translate(16px, 16px);
    transform: scale(0);
    animation: load2 0.5s 1s linear forwards;
}
.received-request1 {
    transform-origin: 15px 15px;
    transform: translate(24px, 24px);
    transform: scale(0);
    animation: load1 0.5s 1.5s linear forwards;
}

.recieved-request {
    animation: load 2s 5s linear;
}

.minimize{
    animation: minimize 0.5s linear forwards;
}
.maximize{
    animation: maximize 0.5s linear forwards;
}

.switchNextCards3{
    transform: scale(1);
    animation: switchNextCards3 0.5s linear forwards;
}
.switchNextCards2{
    transform: scale(1);
    animation: switchNextCards2 0.5s linear forwards;
}
.switchNextCards1{
    transform: scale(1);
    animation: switchNextCards1 0.5s linear forwards;
}
.switchNextCards0{
    transform: scale(1);
    animation: switchNextCards0 0.5s linear forwards;
}

@keyframes load4 {
    0%{
        transform: scale(0);
    }
    10%{
        transform: scale(0.1);
    }
    100%{
        transform: scale(1);
    }
}
@keyframes load3 {
    0%{
        transform: scale(0);
    }
    10%{
        transform: scale(0.1);
    }
    100%{
        transform: scale(1);
        transform: translate(8px, 8px);
    }
}
@keyframes load2 {
    0%{
        transform: scale(0);
    }
    10%{
        transform: scale(0.1);
    }
    100%{
        transform: scale(1);
        transform: translate(16px, 16px);
    }
}
@keyframes load1 {
    0%{
        transform: scale(0);
    }
    10%{
        transform: scale(0.1);
    }
    100%{
        transform: scale(1);
        transform: translate(24px, 24px);
    }
}
/* @keyframes load {
    from{     
        transform: scale(0);
    }
    to{
        transform: scale(1);
    }
} */

@keyframes minimize {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(0);
        z-index: 0;
    }
}

@keyframes maximize {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
        z-index: 40;
    }
}


@keyframes switchNextCards3 {
    from {
        transform: translate(8px, 8px);
        /* margin-top: 2px;
        margin-left: 2px; */
    }

    to {
        transform: translate(0px, 0px);
        /* margin-top: 0px;
        margin-left: 0px; */
    }
}
@keyframes switchNextCards2 {
    from{
        transform: translate(16px, 16px);
        /* margin-top: 4px;
        margin-left: 4px; */
    }
    to{
        transform: translate(8px, 8px);
        /* margin-top: 2px;
        margin-left: 2px; */
    }
}
@keyframes switchNextCards1 {
    from{
        transform: translate(24px, 24px);
        /* margin-top: 6px;
        margin-left: 6px; */
    }
    to{
        transform: translate(16px, 16px);
        /* margin-top: 4px;
        margin-left: 4px; */
    }
}
@keyframes switchNextCards0 {
    from{
        transform: translate(24px, 24px);
        /* margin-top: 8px;
        margin-left: 8px; */
    }
    to{
        transform: translate(16px, 16px);
        /* margin-top: 6px;
        margin-left: 6px; */
    }
}

.interviews-else{
    padding: 10px;
    padding-top: 50px;
    font-size: 20px;
    color: blue;
    font-weight: 500;
    text-align: center;
}

/* -------------- Ignore ----------------- */