.blink {
    color: white !important;
    cursor: pointer !important;
    animation: blink2 0.5s infinite ease-in-out;

  }

  .fa-edit-icon {
    pointer-events: auto;
  }

  .fa-edit-icon:hover {
    zoom: 1.1;
    opacity: 0.8;
    cursor: pointer;
  }

  .fa-edit-icon:focus {
    zoom: 0.9;
    opacity: 1;
  }
  
  @keyframes blink2 {
    from {
      border: 1px solid black;
      background-color: black;
    border-radius:"10px"

    }
  
    to {
      border: 1px solid red;
    border-radius:"10px";
      background-color: red;
    }
  }