.welcome{
    display: grid;
    place-items: center;
    width: 100vw;
    min-height: calc(100vh - 70px);
}

.welcome__box{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.welcome__box > h1{
    color: red;
    font-size: 30px;
}

@media screen and (max-width: 600px) {
    .welcome__box > h1{
        font-size: 20px!important;
    }
}

.welcome__box > h1 > span{
    font-size: 40px;
    font-weight: 600;
}

.welcome__box > p{
    color: #02A3E8;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}

.welcome__box > p:nth-last-child(1){
    color: black;
}