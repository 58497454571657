.studentend{
    height: 100vh;
    /* margin-top: 200px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    padding: 0px 20px;
}

.studentend>a{
    align-self: center;
}

@media screen and (min-width: 700px) {
    .studentend{
        align-items: center;
    }
}