.privacyHead {
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    padding-bottom: 20px;
    color: black !important;
}

.privacySubHead {
    font-size: 25px;
    padding-top: 10px;
    font-weight: 600;
}

.privacyText {
    font-size: 18px;
    padding: 10px 0;
    font-family: 'Ubuntu', sans-serif;
    font-size: 1rem!important;
    color: #333333!important;
    font-weight: 400!important;
    line-height: 2rem!important;
}

.privacyWrapper {
    padding: 30px 10%;
}

@media screen and (max-width: 768px) {
    .privacyWrapper {
        padding: 30px 5%!important;
    }
}

@media screen and (max-width: 480px) {
    .privacyWrapper {
        padding: 30px 15px;
    }
}