p {
    margin: 0;
}

.hover-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
}

.hover-animation:after {
    content: "";
    position: absolute;
    width: 70%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 47px;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.stickynavbar {
    position: sticky;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: #fff;
    z-index: 500;
}

.updated {
    padding: 5px;
    color: orange;
    border: 2px solid orange;
    border-radius: 5px;
    margin-left: 70px;
    margin-right: 20px;
}

.cancelled {
    padding: 5px;
    color: red;
    border: 2px solid red;
    border-radius: 5px;
    margin-left: 10px;
}