.upcoming-interview h2 {
    /* margin: 0 0 10px; */
    text-align: center;
}

.upcoming-interview .view-btn {
    display: inline-block;
    border: 1px solid black;
    background-color: black;
    color: white;
    width: 100%;
    margin: 0 auto;
    padding: 8px 6px;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
}

.view-btn:hover {
    background-color: white;
    color: black;
}

.join-meeting-btn {
    display: block;
    border: 1px solid #1265a8;
    background-color: #1265a8;
    color: white;
    padding: 4px 8px;
    margin-top: 10px;
}


.join-meeting-btn:hover {
    color: #eaadad;
    background-color: white;
}

.upcoming-interview .sent-item-btns {
    justify-content: center;
}


/* Riboon */

.ribbon {
    width: 100px;
    height: 100px;
    /* width: 50px;
    height: 50px; */
    overflow: hidden;
    position: absolute;
    display: none;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
}

.ribbon span {
    position: absolute;
    position: relative;
    display: block;
    width: 125px;
    /* height: 30px; */
    padding: 5px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 700 12px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
}

/* top right*/
.ribbon-top-right {
    top: -10px;
    right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
}

.ribbon-top-right::before {
    top: 0;
    left: 0;
}

.ribbon-top-right::after {
    bottom: 0;
    right: 0;
}

.ribbon-top-right span {
    left: 3px;
    top: 25px;
    transform: rotate(45deg);
}

.active{
    display: unset;
}